import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    transferContainer: {
      marginTop: 40,
      border: '1px solid #E9EAEC',
      borderRadius: 6,
      maxHeight: 560,
      maxWidth: '100%',
      margin: '12px auto 0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '25px',
      width: 'calc(100% - 1px)',
      '@media (min-height: 768px)': {
        height: 560,
        margin: '12px auto 0',
        padding: '40px 50px',
      },
      '@media (max-width: 1023px)': {
        height: 560,
        maxHeight: 'initial',
        padding: '40px 442px',
      },
      '@media (max-width: 767px)': {
        margin: '12px auto 0',
        padding: '40px 442px',
      },
      '& *': {
        boxSizing: 'border-box',
      },
    },
    containtWrappe: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px', // Réduire l'écart si nécessaire
      marginBottom: 8,
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%', // Ajoutez ceci pour que le parent ait également 100% de largeur
      // marginBottom: 20,
    },
    typography: {
      fontWeight: 600,
      marginRight: '10px',
      fontSize: 20,
    },
    textField: {
      width: '100%',
      maxWidth: 700,
      background: '#FFFFFF',
      opacity: 1,
      margin: '0 0 10px',
      '@media (max-width: 639px)': {
        width: '100%',
      },
      '& label': {
        color: '#A8AAB3',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          // borderColor: '#B48A4E', // Assurez-vous que la couleur de la bordure est définie
        },
        '&:hover fieldset': {
          borderColor: '#B48A4E', // Même couleur au survol
        },
        '&.Mui-focused fieldset': {
          borderColor: '#B48A4E', // Même couleur lorsque le champ est actif
        },
        height: 45, // Ajuster la hauteur ici
        '& .MuiOutlinedInput-input': {
          height: '100%', // Utilise toute la hauteur disponible
          padding: '10px', // Ajuster le padding
          boxSizing: 'border-box',
        },
      },
      '& input': {
        borderRadius: 6,
        fontSize: '1.1em',
        '&:focus': {
          outline: 'none',
        },
      },
    },
    cardNotice: {
      marginLeft: '20%',
      width: '100%',
      fontSize: 15,
    },

    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: '100%',
    },

    textPM: {
      fontSize: 20,
      marginBottom: 20,
    },
    btndialog: {
      boxShadow: 'none',
      fontSize: 15,
      height: 42,
      letterSpacing: 0.57,
      lineHeight: '16px',
      textAlign: 'center',
      margin: '0 6px',
      padding: 10,
      width: 190,
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
    btnConfirmer: {
      backgroundColor: '#B48A4E',
      color: '#fff',
      boxShadow: '0 3px 3px rgb(0 0 0 / 51%)',
      padding: '10px 40px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '300',
      marginBottom: 20,
    },
    redBorderRounded: {
      border: '2px solid #db4f4fd7',
      borderRadius: '8px',
      padding: '16px',
      width: '65%',
    },
  }),
);
