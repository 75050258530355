import { Box } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { ArrowUpward, DateRange } from "@material-ui/icons";
import React, { FC } from "react";
import { useStyles } from "./style";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { TransactionMethod } from "../../types/graphql-global-types";
import { formatAmountLetter } from "../../utils/helpers";
import Pictogram from "../CustomPithogramme/Pithogramme";

export interface CardProps {
  devise?: string;
  credit: number;
  nameUser: string;
  color: string;
  code: string;
  shortCode: string;
  date: string;
  label: string;
  transactionMethod: TransactionMethod;
  heure: string;
}
export const CardHistories: FC<CardProps> = ({
  devise,
  credit,
  date,
  nameUser,
  label,
  transactionMethod,
  heure,
  code,
  shortCode,
  color
}) => {
  const classes = useStyles();

  const transactionMeth = new Map();

  transactionMeth.set("PAYER", "Paiement");
  transactionMeth.set("DON_APPORT", "Don ou Apport");
  transactionMeth.set("TRANSFERT_CYTOIENS", "Transfert citoyens");
  transactionMeth.set("AVANCE_SOLIDAIRE", "Avance solidaire");
  transactionMeth.set("VERSEMENT_SALAIRE", " Versement salaire");
  transactionMeth.set("CONVERSION", " Conversion");

  return (
    <Card className={classes.cardRoot}>
      <CardContent className={classes.cardcontent}>
        <Box className={classes.boxcard}>
          <Box className={classes.boxTop}>
            <Pictogram color={color || '#4B7FD5'} shortCode={shortCode || ''} />
            <Box className={classes.details}>
              <Typography variant="h5">{code}</Typography>
              <Typography variant="h6">
                {formatAmountLetter(
                  new Intl.NumberFormat("fr-FR").format(credit)
                )}
              </Typography>
              <Typography style={{ fontSize: "10px" }}>
                {transactionMeth.get(transactionMethod)}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.arrowIcon}>
            {label === "SENT" ? (
              <ArrowDownwardIcon style={{ color: "#F35162" }} />
            ) : (
              <ArrowUpward style={{ color: "#2EC114" }} />
            )}
          </Box>
        </Box>
        <Box className={classes.boxText}>
          <Typography className={classes.pos} variant="h6">
            {nameUser}
          </Typography>
          <Box className={classes.date}>
            <Box>
              <DateRange />
            </Box>
            <Box>
              <Typography className={classes.dateText}>{date}</Typography>
              <Typography className={classes.dateText}>{heure}</Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
