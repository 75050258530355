import React, { useState } from 'react';
import { CardContent } from '@material-ui/core';
import { useStyles } from '../style';
import CardSetting from '../../../../common/CardSetting/CardSetting';
import { useMutation, useQuery } from '@apollo/client';
import {
  setting,
  setting_setting,
} from '../../../../graphql/Setting/__generated__/setting';
import { GET_SETTING } from '../../../../graphql/Setting/query';
import {
  updateSetting,
  updateSettingVariables,
} from '../../../../graphql/UpdateSetting/__generated__/updateSetting';
import { UPDATE_SETTING } from '../../../../graphql/UpdateSetting/mutation';

const settingInit: setting_setting = {
  __typename: 'Setting',
  id: '', // Ajouter un champ `id` si nécessaire
  amountOfCreationCostLegalEntity: 0,
  amountOfCreationCostNaturalPerson: 0,
  amountOfValidationCost: 0,
  numberOfValidatorsLegalEntity: 0,
  numberOfValidatorsNaturalPerson: 0,
  payoutAmount: 0,
}

const Setting: React.FC = () => {
  const [lastSetting, setLastSetting] = useState<setting_setting>({...settingInit});
  const [newSetting, setNewSetting] = useState<setting_setting>({...settingInit});

  const style = useStyles();

  const {
    data: dataSetting,
    loading: loadSetting,
    refetch,
  } = useQuery<setting>(GET_SETTING, {
    onCompleted: (data) => {
      if (data?.setting) {
        const {setting} = data;
        setNewSetting({...setting});
        setLastSetting({...setting});
      }
    },
  });

  const [
    updateSetting,
    { loading: loadingUpdateSetting },
  ] = useMutation<updateSetting, updateSettingVariables>(UPDATE_SETTING);

  const handleFieldChange = (
    field: keyof setting_setting,
    value: number,
  ) => {
    setNewSetting((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  const handleOnBlur = async (name: keyof setting_setting) => {
    try {
      if (lastSetting[name] !== newSetting[name]) {
        const { data } = await updateSetting({
          variables: {
            updateSettingInput: {
              id: newSetting.id || '',
              [name]: newSetting[name]
            },
          },
        });
  
        if (data?.updateSetting) {
          setLastSetting((prev) => ({
            ...prev,
            [name]: newSetting[name]
          }))
        }
      }
    } catch (err) {
      console.error('Error updating setting:', err);
    }
  }

  return (
    <>
      <CardContent className={style.cardContent}>
        <CardSetting
          title="Montant du coût création / personne morale:"
          text="Coût CPM"
          priceT={newSetting.amountOfCreationCostNaturalPerson}
          name={'amountOfCreationCostNaturalPerson'}
          loading={loadingUpdateSetting}
          onChange={handleFieldChange}
          handleOnBlur={handleOnBlur}
        />
      </CardContent>
      <CardContent className={style.cardContent}>
        <CardSetting
          title="Montant du coût création / personne physique:"
          text="Coût CPP"
          priceT={newSetting.amountOfCreationCostLegalEntity}
          name={'amountOfCreationCostLegalEntity'}
          loading={loadingUpdateSetting}
          onChange={handleFieldChange}
          handleOnBlur={handleOnBlur}
        />
      </CardContent>
      <CardContent className={style.cardContent}>
        <CardSetting
          title="Montant du coût validation / personne physique:"
          text="Coût VPP"
          priceT={newSetting.amountOfValidationCost}
          name={'amountOfValidationCost'}
          loading={loadingUpdateSetting}
          onChange={handleFieldChange}
          handleOnBlur={handleOnBlur}
        />
      </CardContent>
      <CardContent className={style.cardContent}>
        <CardSetting
          title="Montage du reversement:"
          text="Reversement PM-PP"
          priceT={newSetting.payoutAmount}
          name={'payoutAmount'}
          loading={loadingUpdateSetting}
          onChange={handleFieldChange}
          handleOnBlur={handleOnBlur}
        />
      </CardContent>
      <CardContent className={style.cardContent}>
        <CardSetting
          title="Nombre de validateurs pour une personne morale:"
          text="Nombre PM"
          priceT={newSetting.numberOfValidatorsLegalEntity}
          name={'numberOfValidatorsLegalEntity'}
          loading={loadingUpdateSetting}
          onChange={handleFieldChange}
          handleOnBlur={handleOnBlur}
        />
      </CardContent>
      <CardContent className={style.cardContent}>
        <CardSetting
          title="Nombre de validateurs pour une personne physique:"
          text="Nombre PP"
          priceT={newSetting.numberOfValidatorsNaturalPerson}
          name={'numberOfValidatorsNaturalPerson'}
          loading={loadingUpdateSetting}
          onChange={handleFieldChange}
          handleOnBlur={handleOnBlur}
        />
      </CardContent>
    </>
  );
};
export default Setting;
