import {
  Card,
  CardActions,
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core';
import { ArrowRightAlt } from '@material-ui/icons';
import { FC } from 'react';
import { CustomButton } from '../../../../common/CustomButton/CustomButton';
import { GET_BALANCE_balances } from '../../../../graphql/Balances/__generated__/GET_BALANCE';
import Pictogram from '../../../../common/CustomPithogramme/Pithogramme';
interface cartProps {
  style: any;
  devise?: string;
  value: string;
  balance: GET_BALANCE_balances | null
  handleClick?: (value: any) => void;
}

const Cart: FC<cartProps> = ({ style, value, handleClick, balance }) => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#B48A4E',
      },
    },
  });

  return (
    <>
      <Card className={style.cardItem}>
        <Pictogram color={balance?.color || '#4B7FD5'} shortCode={balance?.shortCode || ''} />
        <CardActions className={style.actionBloc}>
          <ThemeProvider theme={theme}>
            <CustomButton
              {...{
                nameBtn: 'TRANSFÉRER',
                color: 'primary',
                variant: 'contained',
                className: style.btnTransfert,
                onClick: handleClick,
                endIcon: <ArrowRightAlt />,
              }}
            />
          </ThemeProvider>
        </CardActions>
      </Card>
    </>
  );
};

export default Cart;
