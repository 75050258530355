import gql from 'graphql-tag';

export const CREATE_COIN = gql`
  mutation createCoin($createCoinInput: createCoinInput) {
    createCoin(createCoinInput: $createCoinInput) {
      transactionHistories {  
        id
        transaction_hash
        transactionMethod
        from
        sender {
          id
          name
          lastName
          userName
          legalEntity {
            id
            denomination
          }
        }
        to
        recipient {
          id
          name
          lastName
          userName
          legalEntityId
          legalEntity {
            id
            denomination
          }
        }
        amount
        currency
        label
      }
    }
  }
`;
