import {
  Box,
  Button,
  createMuiTheme,
  TextField,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useStyles } from './style';
import CardNotice from '../../../../common/cardeNotice/CardNotice';
import {
  TEXTÈ_CONVERSION_AFFINITAIRE,
  TEXTÈ_CONVERSION_COIN,
} from '../../../../constant/TextNotice/TextNotice';
const EUCoin: React.FC = () => {
  const style = useStyles();
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#B48A4E',
      },
    },
  });
  return (
    <Box className={style.containtWrappe}>
      <Box className={style.wrapper}>
        <Typography className={style.textPM}>
          Choisissez quelle monnaie doit être convertie en EU-coins:
        </Typography>
        <Box className={style.flexContainer}>
          <Typography>
            <span className={style.typography}>UE-</span>
          </Typography>

          <TextField
            className={style.textField}
            type="monnaie"
            name="monnaie"
            label="Nom de votre monnaie"
            variant="outlined"
          />
        </Box>
        <Box>
          <Typography className={style.textPM}>
            Choisissez le montant à convertir:
          </Typography>
          <Box>
            <TextField
              className={style.textField}
              type="number"
              name="monnaie"
              defaultValue={0}
              variant="outlined"
            />
          </Box>
        </Box>
      </Box>
      <Box className={style.buttonContainer}>
        <ThemeProvider theme={theme}>
          <Button
            // onClick={onClick}
            color="primary"
            variant="contained"
            size="medium"
            className={style.btnConfirmer}
          >
            CONVERTIR en EU affinitaire
          </Button>
        </ThemeProvider>
      </Box>
      <Box className={style.cardNotice}>
        <CardNotice width="80%" children={TEXTÈ_CONVERSION_COIN} />
      </Box>
    </Box>
  );
};
export default EUCoin;
