import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    rootContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
      padding: '22px 28px 0',
      width: '100%',
      height: '100vh',
      '@media (max-width: 1023px)': {
        padding: '12px 14px 0',
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      height: '100%',
      width: '100%',
    },
    headerMenu: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: '0px',
      gap: '53px',
      width: '100%',
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: '10px',
    },

    groupeitem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px',
    },
    titleItem: {
      fontWeight: 'bold',
      fontSize: '20px',
    },
    btnCancel: {
      fontSize: 15,
      height: 42,
      letterSpacing: 0.57,
      lineHeight: '16px',
      textAlign: 'center',
      margin: '10px 6px',
      padding: 10,
      backgroundColor: 'rgb(180, 138, 78)',
      color: 'rgb(255, 255, 255)',
      width: 300,
      boxShadow: '0 4px 0px rgb(0 0 0 / 55%)',
      border: 'none',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: 'rgb(160, 120, 68)',
      },

      '&:active': {
        backgroundColor: 'rgb(140, 105, 60)',
      },
    },
    cardContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      margin: '5px 0',
      width: '100%',
      flexWrap: 'wrap',
      gap: '20px',
    },
    card: {
      flex: '1 1 calc(33.33% - 40px)',
      minWidth: '800px',
      maxWidth: '100%',
      border: '1px solid #D3D3D3',
      borderRadius: '8px',
      overflow: 'hidden',
      // padding: '5px',
      maxHeight: '570px', // Définit une hauteur maximale selon vos besoins de design
      overflowY: 'auto', // Ajoute un défilement vertical uniquement lorsque le contenu dépasse
      padding: '16px',
    },
    card1: {
      flex: '1 1 calc(33.33% - 40px)',
      minWidth: '800px',
      maxWidth: '100%',
      border: '1px solid #D3D3D3',
      borderRadius: '8px',
      overflow: 'hidden',
      padding: '5px',
    },
    cardContent: {
      padding: '12px',
    },
  }),
);
