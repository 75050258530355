import gql from 'graphql-tag';

export const VALIDATE_COIN = gql`
  mutation validateCoin($validateCoinInput: validateCoinInput) {
    validateCoin(validateCoinInput: $validateCoinInput) {
      id
      transaction_hash
      from
      coinId
      to
      amount
      label
      sender {
        id
      }
      recipient {
        id
      }
    }
  }
`;
